import { createAnalytics } from '@holidu/lib/universal/analytics'
import { InternalPageEventData } from '@holidu/lib/universal/analytics/types'
import { INTERNAL_V5_URL } from 'consts/universal'

function track() {
  const { pageType, domainId, gaCode, locale, currency, userId, uuid } =
    window.pageTrackingContext

  const setupAnalytics = createAnalytics({
    internalUrl: INTERNAL_V5_URL.replace('{domainId}', domainId)
      .replace('{locale}', locale)
      .replace('{currency}', currency),
    googleAnalyticsKey: gaCode,
  })

  const analytics = setupAnalytics({
    ga: gaCode,
    userId,
    uuid,
    locale,
    currency,
    isUserSignedIn: false,
    clientVersion: window.appVersion,
    userAgent: window.navigator.userAgent,
  })

  const pageEventData: InternalPageEventData = {
    eventType: 'page',
    abTests: [],
    customEventData: { pageType },
  }

  analytics.trackInternalBrowserEvent(pageEventData)()
  analytics.trackGoogleAnalytics('send', 'pageview')
}

track()
